import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform, ToastController } from '@ionic/angular';
import { ConnectionService } from 'ng-connection-service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';




export enum ConnectionStatus {
    Online,
    Offline
}

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    connectSubscription: Subscription;
    disconnectSubscription: Subscription;

    private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

    constructor(private network: Network, private toastController: ToastController, private platform: Platform, private connectionService: ConnectionService) {
        this.platform.ready().then(() => {


            this.initializeNetworkEvents();
            let status = this.network.type !== 'none' ? ConnectionStatus.Online : ConnectionStatus.Offline;
            this.status.next(status);



        });
    }

    public initializeNetworkEvents() {

        if (this.platform.is('cordova')) {

            this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
                if (this.status.getValue() === ConnectionStatus.Online) {
                    this.updateNetworkStatus(ConnectionStatus.Offline);
                }
            });

            this.connectSubscription = this.network.onConnect().subscribe(() => {
                if (this.status.getValue() === ConnectionStatus.Offline) {
                    this.updateNetworkStatus(ConnectionStatus.Online);
                }
            });

        } else {
            this.connectionService.monitor().subscribe(isConnected => {

                this.updateNetworkStatus(isConnected ? ConnectionStatus.Online : ConnectionStatus.Offline);
            })
        }


    }

    private async updateNetworkStatus(status: ConnectionStatus) {
        this.status.next(status);

        let connection = status == ConnectionStatus.Offline ? 'Offline' : 'Online';

        let message= status == ConnectionStatus.Offline ? 'İnternet bağlantınız yok!' : 'İnternet bağlantınız var!';

        let toast = this.toastController.create({
            message: message,
            duration: 3000,
            position: 'bottom',
            color:'dark'
        });
        toast.then(toast => toast.present());
    }

    public onNetworkChange(): Observable<ConnectionStatus> {
        return this.status.asObservable();
    }

    public getCurrentNetworkStatus(): ConnectionStatus {
        return this.status.getValue();
    }


    public unsubscribeNetworkEvents() {
        this.connectSubscription.unsubscribe();
        this.disconnectSubscription.unsubscribe();
    }
}





