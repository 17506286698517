import {Injectable} from '@angular/core';
import {StorageService} from './storage-service';

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    private activePlatform: 'web' | 'mobile-web' | 'ios' | 'android';
    private innerWidth: number;

    constructor(private storageService: StorageService) {
    }

    getPlatform(): string {
        return this.activePlatform;
    }

    setPlatform(platform: 'web' | 'mobile-web' | 'ios' | 'android'): void {
        this.activePlatform = platform;
        this.storageService.set('activePlatform', this.activePlatform);
    }

    getInnerWidth() {
        return this.innerWidth;
    }

    setInnerWidth(width: number) {
        this.innerWidth = width;
        this.storageService.set('innerWidth', this.innerWidth);
    }


    

}
