import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class ToasterService {

    toast: HTMLIonToastElement;
    constructor(public toastController: ToastController) {
    }

    async create(
        message: string,
        color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark',
        duration: number = 3000,
        position: 'top' | 'bottom' | 'middle' = 'top'
    ): Promise<HTMLIonToastElement> {

        const toast = await this.toastController.create({color, message, duration, position});
        await toast.present();
        return toast;

    }


    async showLoadingToaster(message: string){
        this.toast =  await this.toastController.create({ color: 'warning',message: 'Bu işlem uzun sürebilmektedir. Lütfen tamamlanana kadar bu ekranı kapatmayınız ve başka bir tarayıcı ile yeniden yükleme yapmayınız.',duration: 0,position: 'top'});
        await this.toast.present();
    }

    hideLoadingToaster(){
         this.toast.dismiss();
    }
}
