import { Injectable } from "@angular/core";
import { Push, PushObject, PushOptions } from "@ionic-native/push/ngx";
import { Platform, ToastController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { of } from "rxjs";
import { ToasterService } from "./toaster-service";
import { LoadingService } from "./loading.service";

@Injectable({ providedIn: "root" })
export class PushNotificationService {
  options: PushOptions = {
    android: {
      // clearBadge: true,
      // clearNotifications: true
    },
    ios: {
      alert: "true",
      badge: true,
      sound: "true",
    },
    windows: {},
    browser: {},
  };
  private API_ROOT = environment.apiRoot;
  private pushObject: PushObject;

  constructor(
    public push: Push,
    public platform: Platform,
    public loadingService: LoadingService,
    public toasterService: ToasterService,
    private httpClient: HttpClient
  ) {}

  initNotificationV2(userId) {
    if (this.platform.is("android") || this.platform.is("ios")) {
      const pushObject = this.push.init(this.options);

      pushObject.on("registration").subscribe(
        (registration: any) => {
          const registrationId = registration.registrationId;
          
          setTimeout(() => {
            let registerInfo = {
              deviceId: registrationId,
              userId: userId,
            };

            let observable = this.httpClient.put(
              `${this.API_ROOT}Users/RegisterUserDeviceId`,
              registerInfo
            );
            this.loadingService.wrapLoading(observable).subscribe(
              (response) => {
                //subscription$.unsubscribe();
              },
              (err) => {
                this.toasterService.create(
                  "İşlem sırasında hata oluştu!",
                  "dark",
                  3000,
                  "bottom"
                );
              }
            );
          }, 100);
        },
        (err) => {}
      );

      pushObject.on("notification").subscribe((data) => {
        this.toasterService.create(data.title, "dark", 3000, "bottom");
      });

      pushObject.on("error").subscribe((e) => {
        this.toasterService.create(e.message, "dark", 3000, "bottom");
      });
    }
  }

  initPushNotification(userId) {
    this.platform.ready().then(() => {
      if (this.platform.is("cordova")) {
        this.registerNotification().subscribe(
          (registration: any) => {
            const registrationId = registration.registrationId;

            setTimeout(() => {
              let observable = this.registerForPushNotifications({
                deviceId: registrationId,
                userId: userId,
              });
              this.loadingService.wrapLoading(observable).subscribe(
                (response) => {},
                (err) => {}
              );
            }, 100);
          },
          (err) => {
            this.toasterService.create(
              "Hata! İşlem sırasında hata oluştu! Detay: " + err,
              "dark",
              2000,
              "bottom"
            );
          }
        );
      }
    });
  }

  public registerNotification() {
    this.pushObject = this.push.init(this.options);
    return this.pushObject.on("registration");
  }

  public onNotficationRecieved() {
    if (this.platform.is("android") || this.platform.is("ios")) {
      const pushObject: PushObject = this.push.init(this.options);

      // here call register first

      pushObject.on("notification").subscribe((data) => {
        this.toasterService.create(data.title, "dark", 3000, "bottom");
      });

      pushObject.on("error").subscribe((e) => {
        this.toasterService.create(e.message, "dark", 3000, "bottom");
      });
    }
  }

  registerForPushNotifications(
    registerInfo: { deviceId: string; userId: string } = null
  ) {
    const pushObject: PushObject = this.push.init(this.options);

    // here call register first

    pushObject.on("notification").subscribe((data) => {
      this.toasterService.create(data.title, "dark", 200, "bottom");
    });

    pushObject.on("error").subscribe((e) => {
      this.toasterService.create(e.message, "dark", 200, "bottom");
    });

    if (registerInfo) {
      return this.httpClient.put(
        `${this.API_ROOT}Users/RegisterUserDeviceId`,
        registerInfo
      );
    } else {
      return of(null);
    }
  }
}
