import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { BaseResponse } from "./base-response";
import { BehaviorSubject, Observable } from "rxjs";
import { WhoAmIDto } from "./who-am-i-dto";
import { StorageService } from "./storage-service";
import { Ability, AbilityBuilder } from "@casl/ability";
import { TranslateService } from "@ngx-translate/core";
import { loadMessages, locale } from "devextreme/localization";
import { DateAdapter } from "@angular/material/core";
// @ts-ignore
import enMessages from "devextreme/localization/messages/en.json";

@Injectable({ providedIn: "root" })
export class WhoAmIService {
  API_ROOT = environment.apiRoot;

  readonly whoAmI = new BehaviorSubject<WhoAmIDto>(null);
  private _whoAmI: WhoAmIDto;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private ability: Ability,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>
  ) {}

  get(): Observable<WhoAmIDto> {
    const url = `${this.API_ROOT}Auth/WhoAmI`;

    return this.httpClient.get(url).pipe(
      map((response: BaseResponse<WhoAmIDto>) => {
        this._whoAmI = new WhoAmIDto(response.data);
        this.updateAbility(this._whoAmI);
        this.whoAmI.next(this._whoAmI);
        if (this._whoAmI.userType == 2) {
          this.translateService.use("en");
          this.dateAdapter.setLocale("en");

          loadMessages(enMessages);
          locale("en");
        }

        return this._whoAmI;
      })
    );
  }

  private updateAbility(user: WhoAmIDto) {
    const { can, rules } = new AbilityBuilder<Ability>();
    user.permissions.map((permission) => can(permission, "app"));
    this.ability.update(rules);
  }

  /*getStorage() {
        if (this.whoAmI == undefined) {
            return this.storageService.get('who-am-i').pipe(
                map((whoAmI: WhoAmIDto) => {

                    if (!whoAmI) {
                        return null;
                    }

                    this.whoAmI = whoAmI;
                    return this.whoAmI;

                })
            );
        } else {
            return of(this.whoAmI);
        }
    }*/
}
