import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthorizationService} from './authorization.service';
import {map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private router: Router, private authorizationService: AuthorizationService) {
    }

    canActivate() {
        return this.authorizationService.isAuthenticated().pipe(map(
            (isLogin) => {
                if (isLogin) {
                    this.router.navigate(['./home']);
                    return false;
                }
                return true;
            }
        ));
    }
}
