import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {flatMap, map} from 'rxjs/operators';
import {AuthorizationService} from './authorization.service';
import {WhoAmIService} from './who-am-i.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
                private authorizationService: AuthorizationService,
                private whoAmIService: WhoAmIService) {
    }

    canActivate() {
        return this.authorizationService.isAuthenticated().pipe(flatMap(
            (isLogin) => {
                if (!isLogin) {
                    this.router.navigate(['/login']);
                }
                return this.whoAmIService.get().pipe(map(
                    () => true
                ));
            }
        ));
    }
}
