import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from './storage-service';
import { AuthenticationDtoIn } from '../login/authentication-dto-in';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    private authentication: AuthenticationDtoIn = undefined;
    _userActionOccured: Subject<void> = new Subject();

    constructor(private storageService: StorageService) {
    }


    getToken(): Observable<string> {

        if (this.authentication == undefined) {
            return this.storageService.get('authentication').pipe(
                map((authenticationFromStorage: AuthenticationDtoIn) => {

                    if (!authenticationFromStorage) {
                        return '';
                    }

                    this.authentication = authenticationFromStorage;
                    return authenticationFromStorage.key;

                })
            );
        } else {
            return of(this.authentication.key);
        }
    }

    isAuthenticated(): Observable<boolean> {
        if (this.authentication) {
            return of(!!this.authentication.key);
        } else {
            return this.storageService.get('authentication').pipe(
                map((authentication: AuthenticationDtoIn) => {
                    if (authentication) {
                        this.authentication = authentication;
                        return !!this.authentication.key;
                    }
                    return false;
                }
                ));
        }
    }

    setAuthentication(authentication: AuthenticationDtoIn): Observable<AuthenticationDtoIn> {

        return this.storageService.set('authentication', authentication).pipe(map(
            (authenticationDto: AuthenticationDtoIn) => {
                this.authentication = authenticationDto;
                return this.authentication;
            }
        ));

    }

    async setImpersonated(impersonatedToken: AuthenticationDtoIn) {

        await this.storageService.get('impersonator').subscribe((impersonatorToken: AuthenticationDtoIn) => {
            if (!impersonatorToken) {
                this.storageService.set('impersonator', this.authentication);
            }
        });

        await this.setAuthentication(impersonatedToken);

    }

    async removeImpersonated() {

        await this.storageService.get('impersonator').subscribe((impersonatorToken: AuthenticationDtoIn) => {
            if (impersonatorToken) {
                this.setAuthentication(impersonatorToken);
            }
        });

        await this.storageService.remove('impersonator');

    }

    removeAuthentication(): Observable<any> {
        const observables: Array<Observable<any>> = [];
        observables.push(this.storageService.remove('impersonator'),
            this.storageService.remove('authentication'));
        this.authentication = undefined;

        return forkJoin(observables);
    }

    get userActionOccured(): Observable<void> {
        return this._userActionOccured.asObservable()
    };

    notifyUserAction() {
        this._userActionOccured.next();
    }
}
