import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ToasterService} from './toaster-service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private router: Router,
        private toasterService: ToasterService
    ) {
    }

    handleError(error) {

        if ((error as HttpErrorResponse)) {

            switch ((error as HttpErrorResponse).status) {

                case 400:

                    this.toasterService.create(error.error.messages, 'warning', 8000);
                    break;

                case 403:

                    this.toasterService.create('Kullanıcının erişmek istediği kaynağa yetkisi yok',
                        'danger', 5000);
                    break;

                default:

                    throw error;
                    break;

            }

        } else {
            throw error;
        }

    }

}
