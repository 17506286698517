import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { defer, from, Observable } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { catchError, finalize, flatMap, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  loading;

  constructor(public loadingController: LoadingController) {}

  async present(): Promise<any> {
    this.loading = await this.loadingController.create({
      translucent: true,
    });

    return this.loading.present();
  }

  showLoader(): Observable<any> {
    return defer(async function () {
      this.loading = await this.loadingController.create({
        translucent: true,
      });

      return fromPromise(this.loading.present());
    });
  }

  hideLoader(): void {
    this.loadingController.dismiss();
  }

  public wrapLoading<T>(source: Observable<T>): Observable<T> {
    let loader: any;

    const obs = from(
      this.loadingController.create({
        translucent: true,
      })
    );

    const wrapper = obs.pipe(
      tap((loader2) => {
        loader = loader2;
        loader2.present();
      }),
      flatMap(() => source),

      finalize(() => {
        loader.dismiss();
      })
    );

    return wrapper;
  }
}
