import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {PlatformService} from './core/platform.service';
import DataGrid from 'devextreme/ui/data_grid';
import {loadMessages, locale} from 'devextreme/localization';
import trMessages from 'devextreme/localization/messages/tr.json';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {Subject, Subscription, timer} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {AuthorizationService} from "./core/authorization.service";
import { NetworkService } from './core/network.service';
import { PushNotificationService } from './core/push-notification.service';
import { IonRouterOutlet } from '@ionic/angular';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    host: {
        '(window:resize)': 'setPlatform()'
    }
})
export class AppComponent implements OnInit, OnDestroy {

    minutesDisplay = 0;
    secondsDisplay = 0;

    endTime = 15;

    unsubscribe$: Subject<void> = new Subject();
    timerSubscription: Subscription;

    constructor(
        
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private networkService:NetworkService,
        private pushNotificationService:PushNotificationService,
        private translate: TranslateService,
        private platformService: PlatformService,
        private dateAdapter: DateAdapter<any>,
        private authorizationService: AuthorizationService,
        private navCtrl: NavController
    ) {

        this.initializeApp();
        

    }
    ngOnInit(): void {
        
    }

    onDevexGridRowUpdating(options) {

        options.newData = Object.assign(options.oldData, options.newData);

    }

    setPlatform() {

        if (this.platform.is('ios')) {
            this.platformService.setPlatform('ios');
        } else if (this.platform.is('android')) {
            this.platformService.setPlatform('android');
        } else if (this.platform.is('mobileweb') || window.innerWidth < 992) {
            this.platformService.setPlatform('mobile-web');
        } else {
            this.platformService.setPlatform('web');
        }
        this.platformService.setInnerWidth(window.innerWidth);

        if(this.platform.is('ios')|| this.platform.is('android')){
            this.endTime = 540;
        }
    }

    private initializeApp(): void {

        this.platform.ready().then(() => {
            // this.routerOutlet.swipeGesture = false;
            this.dateAdapter.setLocale('tr');
            loadMessages(trMessages);
            locale('tr');
            this.translate.setDefaultLang('tr');
            this.translate.use('tr');
            this.networkService.initializeNetworkEvents();
            DataGrid.defaultOptions({

                options: {
                    onRowUpdating: this.onDevexGridRowUpdating
                }

            });

            this.setPlatform();
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.userInactivityTimer();

        });

    }

    @HostListener('document:keyup', ['$event'])
    @HostListener('document:click', ['$event'])
    @HostListener('document:wheel', ['$event'])
    resetInactivityTimer() {
        this.authorizationService.notifyUserAction();
    }

    userInactivityTimer() {
        this.resetTimer();
        this.authorizationService.userActionOccured.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(() => {
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
            this.resetTimer();
        });
    }


    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    resetTimer(endTime: number = this.endTime) {
        const interval = 1000;
        const duration = endTime * 60;
        this.timerSubscription = timer(0, interval).pipe(
            take(duration)
        ).subscribe(value =>
                this.render((duration - +value) * interval),
            err => { },
            () => {
                this.authorizationService.removeAuthentication().subscribe(
                    () => this.navCtrl.navigateRoot(['login'])
                );
            }
        )
    }

    private render(count) {
        this.secondsDisplay = this.getSeconds(count);
        this.minutesDisplay = this.getMinutes(count);
    }

    private getSeconds(ticks: number) {
        const seconds = ((ticks % 60000) / 1000).toFixed(0);
        return this.pad(seconds);
    }

    private getMinutes(ticks: number) {
        const minutes = Math.floor(ticks / 60000);
        return this.pad(minutes);
    }

    private pad(digit: any) {
        return digit <= 9 ? '0' + digit : digit;
    }
}
