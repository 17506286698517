import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { TokenInterceptorService } from "./core/token-inteceptor";
import { IonicStorageModule } from "@ionic/storage";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GlobalErrorHandler } from "./core/global-error-handler";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Push } from "@ionic-native/push/ngx";
// import { Push } from "@awesome-cordova-plugins/push/ngx";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { CustomDateAdapter } from "./core/custom-date-adapter";
import { AbilityModule } from "@casl/angular";
import { Ability, PureAbility } from "@casl/ability";
import { Network } from "@ionic-native/network/ngx";
import { NetworkService } from "./core/network.service";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { ConnectionServiceModule } from "ng-connection-service";
import { MSAdal } from "@ionic-native/ms-adal/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { LaunchNavigator } from "@awesome-cordova-plugins/launch-navigator/ngx";
import { ServiceOsaDetailReportPipe } from "./reports/service-osa-detail-report.pipe";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { IonicSelectableModule } from "ionic-selectable";
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType
} from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import { environment } from "src/environments/environment";
import { File } from '@ionic-native/file/ngx';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/*type Actions = 'activity-list';
type Subjects = 'app'

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;*/

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: '/',
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}
@NgModule({
  declarations: [AppComponent, ServiceOsaDetailReportPipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AbilityModule,
    NgxQRCodeModule,
    ConnectionServiceModule,
    IonicSelectableModule,
    MsalModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "tr-TR" },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    Push,
    Network,
    NetworkService,
    InAppBrowser,
    Geolocation,
    MSAdal,
    Keyboard,
    BarcodeScanner,
    LaunchNavigator,
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },

    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    MsalService,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
