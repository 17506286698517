import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/auth-guard.service";
import { LoginGuard } from "./core/login-guard.service";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "change-forgotten-password",
    loadChildren: () =>
      import(
        "./change-forgotten-password/change-forgotten-password.module"
      ).then((m) => m.ChangeForgottenPasswordPageModule),
  },
  {
    path: "mobile",
    loadChildren: () =>
      import("./layout/mobile-layout/mobile-layout.module").then(
        (m) => m.MobileLayoutPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./layout/default-layout/default-layout.module").then(
        (m) => m.DefaultLayoutPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "custom-filter-dialog",
    loadChildren: () =>
      import(
        "./task/web/custom-filter-dialog/custom-filter-dialog.module"
      ).then((m) => m.CustomFilterDialogPageModule),
  },
  {
    path: "advanced-payment-type-list",
    loadChildren: () =>
      import(
        "./advanced-form-type/advanced-form-type-list/advanced-form-type-list.module"
      ).then((m) => m.AdvancedFormTypeListPageModule),
  },
  {
    path: "advanced-payment-type-detail",
    loadChildren: () =>
      import(
        "./advanced-form-type/advanced-form-type-detail/advanced-form-type-detail.module"
      ).then((m) => m.AdvancedFormTypeDetailPageModule),
  },
  {
    path: "overtime-form-report",
    loadChildren: () =>
      import(
        "./reports/digital-form-reports/overtime-form-report/overtime-form-report.module"
      ).then((m) => m.OvertimeFormReportPageModule),
  },
  {
    path: "leave-form-report",
    loadChildren: () =>
      import(
        "./reports/digital-form-reports/leave-form-report/leave-form-report.module"
      ).then((m) => m.LeaveFormReportPageModule),
  },
  {
    path: 'leave-form-detail-report',
    loadChildren: () => import('./reports/digital-form-reports/leave-form-detail-report/leave-form-detail-report.module').then(m => m.LeaveFormDetailReportPageModule)
  },
  {
    path: 'expense-form-detail-report',
    loadChildren: () => import('./reports/digital-form-reports/expense-form-report/expense-form-report.module').then( m => m.ExpenseFormReportPageModule)
  },
  {
    path: 'expense-form-report',
    loadChildren: () => import('./reports/digital-form-reports/form-expense-report/form-expense-report.module').then( m => m.FormExpenseReportPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "corrected",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
