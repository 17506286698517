export class WhoAmIDto {

    userFullName: string;
    titleName: string;
    hasImpersonation: boolean;
    impersonatedUserFullName: string;
    userType: number;
    permissions: Array<string>;
    id:string;
    isSupervisor:boolean
    isBUManager:boolean
    isFormApprover: boolean;
    isTechnician:boolean

    constructor(dto: any) {
        this.id=dto.id;
        this.isTechnician=dto.isTechnician;
        this.userFullName = dto.userFullName;
        this.titleName = dto.titleName;
        this.hasImpersonation = dto.hasImpersonation;
        this.impersonatedUserFullName = dto.impersonatedUserFullName;
        this.userType = dto.userType;
        this.permissions = dto.permissions;
        this.isSupervisor=dto.isSupervisor;
        this.isBUManager=dto.isBUManager;
        this.isFormApprover = dto.isFormApprover;

    }

}
