import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthorizationService} from './authorization.service';
import {Observable, of, throwError} from 'rxjs';
import {catchError, flatMap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private authorizationService: AuthorizationService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return this.authorizationService.getToken().pipe(
            flatMap(token => {

                if (token) {

                    request = request.clone({
                        setHeaders: {
                            Authorization: token
                        }
                    });

                }


                return next.handle(request).pipe(
                    catchError(err => {
                        if (err.status == 401) {

                            // logout
                            this.router.navigate(['login']);
                            return this.authorizationService.removeAuthentication();

                        }

                        return throwError(err);
                    })
                );

            })
        );

    }

}
