import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {from, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StorageService {

    constructor(public storage: Storage) {
    }

    get(key: string): Observable<any> {

        return from(this.storage.get(key));

    }

    set(key: string, value: any): Observable<any> {

        return from(this.storage.set(key, value));

    }

    ready(): Observable<LocalForage> {

        return from(this.storage.ready());

    }

    remove(key: string): Observable<any> {

        return from(this.storage.remove(key));

    }

    clear(): Observable<any>{

        return from(this.storage.clear());

    }

}
